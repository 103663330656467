import { Meta, Title } from '@solidjs/meta';
import {
	Avatar,
	Dialog,
	DialogContent,
	DialogTrigger,
	Heading,
	Picture,
	TextLink,
	TroonAccess,
	Container,
	HorizontalRule,
	Section,
	Page,
	LinkButton,
} from '@troon/ui';
import { createSignal, For, Index, onMount, useContext, Show, createEffect } from 'solid-js';
import { createAsync, useSearchParams } from '@solidjs/router';
import { twJoin } from '@troon/tailwind-preset/merge';
import { NoHydration } from 'solid-js/web';
import { firePixel } from '@troon/analytics';
import { Icon } from '@troon/icons';
import { useWindowScrollPosition } from '@solid-primitives/scroll';
import { getConfigValue } from '../../modules/config';
import { AccessProducts } from '../../components/access-products';
import { ImageOverlay } from '../../components/image-overlay';
import { gql } from '../../graphql';
import { cachedQuery } from '../../graphql/cached-get';
import { Grid, GridHalf, GridThird } from '../../components/layouts/grid';
import { SupportButton } from '../../components/support';
import { FrequentlyAskedQuestions } from '../../components/faqs';
import { FacilitiesByGrouping } from '../../components/facility/by-grouping';
import { Benefit } from '../../components/benefit';
import { AccessProductsCtx } from '../../providers/card';
import type { RouteDefinition } from '@solidjs/router';
import type { ComponentProps, JSX, ParentProps } from 'solid-js';

export default function AccessLanding() {
	const [open, setOpen] = createSignal(false);
	const products = useContext(AccessProductsCtx);
	const [searchParams] = useSearchParams();

	const data = createAsync(async () => getData({}), { deferStream: true });

	onMount(() => {
		firePixel('view-content', { content_ids: products().map((p) => p.id) });
	});

	const scroll = useWindowScrollPosition();

	let pic: HTMLImageElement;
	createEffect(() => {
		pic!.style.transform = `scale(${Math.max(scroll.y / window.innerWidth + 1, 1)})`;
	});

	return (
		<>
			<Title>Troon Access | Exclusive savings at more than 150 participating golf courses | Troon</Title>
			<Meta
				name="description"
				content="The ultimate way to experience Troon golf. Exclusive access to savings at more than 150 participating Troon golf courses."
			/>

			<div class="relative flex min-h-64 flex-col items-center bg-white pt-12 sm:min-h-72 md:min-h-96">
				<div
					// eslint-disable-next-line tailwindcss/no-arbitrary-value
					class="absolute inset-0 max-h-[560px] overflow-hidden bg-gradient-to-r from-neutral-950 to-brand-700 md:max-h-[800px]"
				>
					<Picture
						ref={pic!}
						src={`${getConfigValue('IMAGE_HOST')}/digital/hero/access.jpg`}
						alt=""
						sizes={[
							[480, 500],
							[960, 640],
							[1280, 819],
						]}
						// eslint-disable-next-line tailwindcss/no-arbitrary-value
						class="size-full bg-center object-cover brightness-[.65]"
						loading="eager"
					/>
					<ImageOverlay class="absolute -bottom-1" />
				</div>
				<div class="relative z-20 overflow-visible">
					<div class="-mb-10 flex min-h-96 flex-col items-center justify-center gap-4 px-2 text-white sm:px-0">
						<h1 class="max-w-xl text-center text-3xl font-semibold md:text-5xl">
							<TroonAccess class="w-full min-w-64 max-w-96" />
							<span class="sr-only">Troon Access</span>
						</h1>
						<p class="max-w-xl text-center">
							The ultimate way to experience Troon. Exclusive access to savings at more than 150 participating Troon
							courses.
						</p>
					</div>
					<Show when={!searchParams.hideOptions}>
						<Container size="small" class="z-30 overflow-visible text-neutral-950">
							<AccessProducts floating />
						</Container>
					</Show>
				</div>
			</div>

			<Page>
				<Section>
					<Container>
						<CenterContent>
							<Heading as="h2">Better benefits, more access</Heading>
							<p>
								Join the all-new Troon Access program to unlock exclusive savings at more than 150 Troon courses
								including Kapalua, Troon North, and Gamble Sands.
							</p>
						</CenterContent>
						<div class="grid w-full grid-cols-3 gap-16">
							<BenefitCallout>
								<Icon name="flag-custom" class="size-16" />
								<Heading as="h3">Save every time you play</Heading>
								<p>
									Save 15% on unlimited rounds. Troon Access+ members enjoy up to 50% off tee times within 78 hours.
								</p>
							</BenefitCallout>
							<BenefitCallout>
								<Icon name="map-custom" class="size-16" />
								<Heading as="h3">Access 150+ world-class courses</Heading>
								<p>Your benefits apply at all participating Troon courses around the U.S.</p>
							</BenefitCallout>
							<BenefitCallout>
								<Icon name="tag-custom" class="size-16" />
								<Heading as="h3">Exclusive Troon Access Perks & Offers</Heading>
								<p>Enjoy additional benefits like free rental clubs*, partner offers, and savings on merchandise.</p>
							</BenefitCallout>
							<Show when={!searchParams.hideOptions}>
								<div class="col-span-3 flex justify-center">
									<Dialog key="access-products" open={open()} onOpenChange={setOpen}>
										<DialogTrigger appearance="primary" class="shrink grow-0">
											Get started <Icon name="arrow-right-md" />
										</DialogTrigger>
										<DialogContent header="Choose your Troon Access" headerLevel="h3" autoWidth>
											<AccessProducts />
										</DialogContent>
									</Dialog>
								</div>
							</Show>
						</div>
					</Container>
				</Section>

				<Section appearance="primary" class="relative overflow-hidden">
					<Picture
						alt=""
						src={`https://${getConfigValue('MAP_HOST')}/assets/images/map-base.png`}
						class="absolute inset-x-0 top-0 -z-0 w-full bg-top object-cover"
						loading="lazy"
						sizes={[
							[480, 198],
							[960, 396],
							[1280, 528],
						]}
					/>

					<Container class="relative z-0">
						<For each={data()?.featured?.facilities}>
							{(feat, index) => (
								<Picture
									// eslint-disable-next-line tailwindcss/no-arbitrary-value
									class={twJoin(
										'absolute -z-0 hidden aspect-[3/4] w-[300px] rounded xl:block',
										index() % 2 ? '-right-6 top-12 -rotate-6' : '-left-12 bottom-12 rotate-6',
									)}
									alt=""
									src={feat.metadata?.hero?.url}
									sizes={[[300, 400]]}
									loading="lazy"
								/>
							)}
						</For>
						<CenterContent>
							<Heading as="h2">150+ World-class courses.</Heading>
							<p>
								Troon Access members can use their benefits across the entire network of participating Troon courses.
								Search courses by state below:
							</p>
						</CenterContent>

						<FacilitiesByGrouping facilities={data()?.facilities.facilities} inverse />
					</Container>
					<ImageOverlay class="absolute -bottom-1" />
				</Section>

				<NoHydration>
					<Container>
						<Section>
							<div class="mx-auto flex max-w-3xl flex-col items-center gap-4 text-center">
								<Heading as="h2">Additional Member Benefits</Heading>
								<p>
									Join the all-new Troon Access program to unlock exclusive savings at more than 150 Troon courses
									including Kapalua, Troon North, and Gamble Sands.
								</p>
							</div>
							<div class="mb-12 grid grid-cols-2 gap-4 md:gap-8 lg:grid-cols-3">
								<For each={benefits}>
									{(benefit) => {
										const { url, linkText, ...spread } = benefit;
										return <Benefit {...spread} level="h3" />;
									}}
								</For>
							</div>
						</Section>

						<Show when={!searchParams.hideOptions}>
							<Dialog key="access-products" open={open()} onOpenChange={setOpen}>
								<DialogTrigger appearance="primary" class="mx-auto size-fit shrink grow-0">
									Get started <Icon name="arrow-right-md" />
								</DialogTrigger>
								<DialogContent header="Choose your Troon Access" headerLevel="h3" autoWidth>
									<AccessProducts />
								</DialogContent>
							</Dialog>
						</Show>
					</Container>
				</NoHydration>

				<Section>
					<Container>
						<Heading as="h2" size="h2" class="text-center">
							Don’t just take our word for it
						</Heading>
						<Grid>
							<GridThird>
								<Review rating={5}>
									<Quote>
										Booking Troon Access tee times through the new Troon booking experience is as easy as 1, 2, 3. It’s
										fast, easy, and I always know I’m getting the best rates.
									</Quote>
									<Cite location="Birmingham, AL">Jarvis P.</Cite>
								</Review>
							</GridThird>
							<GridThird>
								<Review rating={5}>
									<Quote>
										From top destination courses to my local favorites, having Troon Access across 150 courses has
										helped me play more for less.
									</Quote>
									<Cite location="Scottsdale, AZ">James L.</Cite>
								</Review>
							</GridThird>
							<GridThird>
								<Review rating={5}>
									<Quote>
										As a long-time Troon Cardholder, I love the switch to Troon Access. Instead of watching the clock to
										book at 6pm, I can book as soon as I know I want to play and know I’m getting 15% every time with no
										hassle.
									</Quote>
									<Cite location="Naples, FL">Emily R.</Cite>
								</Review>
							</GridThird>
						</Grid>
					</Container>
				</Section>

				<Container>
					<HorizontalRule />
				</Container>

				<Section>
					<Container>
						<Grid>
							<GridHalf class="flex flex-col gap-6">
								<Heading as="h2" size="h2">
									Frequently asked questions
								</Heading>
								<p>You have questions, we have answers. Not seeing what you’re looking for?</p>
								<div>
									<SupportButton class="shrink" appearance="secondary">
										Contact support
									</SupportButton>
								</div>
							</GridHalf>

							<GridHalf border>
								<FrequentlyAskedQuestions content={faqs} />
							</GridHalf>
						</Grid>
					</Container>
				</Section>
			</Page>
		</>
	);
}

function CenterContent(props: ParentProps) {
	return (
		<div class="relative z-0 mx-auto flex max-w-xl flex-col gap-4 py-16 text-center md:py-24">{props.children}</div>
	);
}

function BenefitCallout(props: ParentProps) {
	return <div class="col-span-3 flex flex-col items-center gap-6 text-center lg:col-span-1">{props.children}</div>;
}

const accessQuery = gql(`
query accessPage {
	facilities: facilitiesV3(supportsTroonAccess: true) {
		facilities {
			...FacilityByState
		}
	}
	featured: facilitiesV3(limit: 2, featured: true) {
		facilities {
			metadata {
	 			hero {
		 			url
		 		}
			}
		}
	}
}`);

const benefits: Array<ComponentProps<typeof Benefit>> = [
	{
		background: `${getConfigValue('IMAGE_HOST')}/digital/logos/hero-avis.jpeg`,
		image: `${getConfigValue('IMAGE_HOST')}/digital/logos/avis.jpg`,
		title: 'Avis',
		content: 'Get 35% off rental cars.',
	},
	{
		background: `${getConfigValue('IMAGE_HOST')}/digital/logos/hero-ship-sticks.png`,
		image: `${getConfigValue('IMAGE_HOST')}/digital/logos/ship-sticks.png`,
		title: 'Ship Sticks',
		content: 'Save $15 off your shipment.',
	},
	{
		background: `${getConfigValue('IMAGE_HOST')}/digital/logos/hero-shot-scope.png`,
		image: `${getConfigValue('IMAGE_HOST')}/digital/logos/shot-scope.png`,
		title: 'Shot Scope',
		content: <>Save $50 on your order.</>,
	},
	{
		background: `${getConfigValue('IMAGE_HOST')}/digital/logos/hero-leupold-golf.png`,
		image: `${getConfigValue('IMAGE_HOST')}/digital/logos/leupold-golf.png`,
		title: 'Leupold Golf',
		content: <>Save 25% on your order.</>,
	},
	{
		background: `${getConfigValue('IMAGE_HOST')}/digital/logos/hero-sunday-golf.png`,
		image: `${getConfigValue('IMAGE_HOST')}/digital/logos/sunday-golf.jpeg`,
		title: 'Sunday Golf',
		content: 'Spend $50 or more and a free Stars and Stripes towel ($34.99 value).',
	},
	{
		background: `${getConfigValue('IMAGE_HOST')}/digital/logos/hero-bad-birdie.png`,
		image: `${getConfigValue('IMAGE_HOST')}/digital/logos/bad-birdie.png`,
		title: 'Bad Birdie',
		content: 'Save 25% on your order.',
	},
	{
		background: `${getConfigValue('IMAGE_HOST')}/digital/logos/hero-zero-friction.jpeg`,
		image: `${getConfigValue('IMAGE_HOST')}/digital/logos/zero-friction.png`,
		title: 'Zero Friction',
		content: <>Save 10% on your order.</>,
	},
	{
		background: `${getConfigValue('IMAGE_HOST')}/digital/logos/hero-full-swing.jpg`,
		image: `${getConfigValue('IMAGE_HOST')}/digital/logos/full-swing.png`,
		title: 'Full Swing',
		content: 'Get 15% off the Full Swing Kit or Kit Studio.',
	},
];

const getData = cachedQuery(accessQuery);

const faqs: Record<string, () => JSX.Element> = {
	'How is Troon Access different from the Troon Card?': () => (
		<>
			<p>
				We’ve taken your feedback and made our new program more simple and cost effective. As a Troon Access member,
				regardless of your tier, you will enjoy tee time savings at all 150+ participating Troon courses, at a fraction
				of the cost of a National Troon Card.
			</p>
			<p>
				<LinkButton href="/access/troon-card-vs-troon-access" class="size-fit shrink">
					Troon Card vs. Troon Access <Icon name="arrow-right-md" />
				</LinkButton>
			</p>
		</>
	),
	'How long are my Troon Access benefits valid?': () => (
		<p>
			Unlike previous versions of the Troon Card, your Troon Access benefits are valid for 1 year after the purchase
			date (i.e. benefits are no longer tied to a calendar year).
		</p>
	),
	'Will guests get my Troon Access benefits?': () => (
		<p>
			The Troon Access program is specific to the purchasing member. To access the benefits of Troon Access, you must be
			a Troon Access or Troon Access+ member.
		</p>
	),
	'Is my Troon Access login the same as my Troon Rewards login?': () => (
		<p>
			Finally, yes! Unlike the Troon Card, you no longer have a separate login to access your benefits. Troon Rewards
			points will be awarded where applicable.
		</p>
	),
	'Where can I book tee times with my Troon Access benefits?': () => (
		<p>
			In our all-new <TextLink href="/tee-times">booking experience</TextLink>, you can access your tee time savings,
			compare tee time availability at multiple courses, set tee time alerts and more! Access tee times on the go using
			our new mobile app.
		</p>
	),
	'Is there a discount for the Military and First Responders?': () => (
		<>
			<p>
				Yes! We offer military and first responder discounts via a promo code. To request a promo code and verify your
				status, please contact us using the link below.
			</p>
			<p>
				<LinkButton href="/access/military-and-first-responders" class="size-fit shrink">
					Contact Us<span class="sr-only"> to get a military or first responder discount</span>
					<Icon name="arrow-right-md" />
				</LinkButton>
			</p>
		</>
	),
};

function Review(props: ParentProps<{ rating: number }>) {
	return (
		<div class="flex flex-col gap-4 rounded border border-neutral p-6">
			<div>
				<Index each={[...Array(props.rating).keys()]}>
					{() => (
						<Icon
							name="star-filled"
							// eslint-disable-next-line tailwindcss/no-arbitrary-value
							class="text-[#FFBE5D]"
						/>
					)}
				</Index>
			</div>
			{props.children}
		</div>
	);
}

function Quote(props: ParentProps) {
	return (
		<blockquote
			// eslint-disable-next-line tailwindcss/no-arbitrary-value
			class="before:content-[open-quote] after:content-[close-quote]"
		>
			{props.children}
		</blockquote>
	);
}

function Cite(props: { children: string; location: string }) {
	return (
		<div class="flex w-full flex-row items-center gap-x-2">
			<Avatar
				class="size-10 text-neutral-600"
				firstName={props.children.split(' ')[0] ?? ''}
				lastName={props.children.split(' ')[1] ?? ''}
			/>
			<p class="flex flex-col gap-px">
				<span class="text-sm font-semibold">{props.children}</span>
				<span class="text-xs">{props.location}</span>
			</p>
		</div>
	);
}

export const route = { info: { hasHero: true }, preload: async () => getData({}) } satisfies RouteDefinition;
